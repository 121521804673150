import { useEffect, useState } from 'react';
import './App.css'
import Modal from './components/Modal';
import SingleCard from './components/SingleCard';
 const cardImages = [
  {"src": "/img/comb.png",matched:false},
  {"src": "/img/cricketbat.png",matched:false},
  {"src": "/img/cup.png",matched:false},
  {"src": "/img/dancer.png",matched:false},
  {"src": "/img/horse.png",matched:false},
  {"src": "/img/iphone.png",matched:false},
  {"src": "/img/parrot.png",matched:false},
  {"src": "/img/pen.png",matched:false},
  {"src": "/img/scooty.png",matched:false},
  {"src": "/img/swoard.png",matched:false},
  {"src": "/img/tennis-rocket.png",matched:false},
  {"src": "/img/tiara.png",matched:false},
  {"src": "/img/tree.png",matched:false},
  {"src": "/img/watch.png",matched:false},
  {"src": "/img/trimmer.png",matched:false}

 ]


function App() {
  const [cards, setCards] = useState([])
  const [turns, setTurns] = useState(0)
  const [choiceOne, setChoiceOne] = useState(null)
  const [choiceTwo, setChoiceTwo] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)

  const shuffleCards = ()=>{
    const shuffledCards =  [...cardImages,...cardImages]
    .sort(()=> Math.random() - 0.5)
    .map((card)=>({...card, id:Math.random()}))
    setChoiceOne(null)
    setChoiceTwo(null)
    setCards(shuffledCards)
    setTurns(0)
  }
  //handle a choice
   const handleChoice = (card)=>{
     choiceOne ? setChoiceTwo(card) : setChoiceOne(card)
   }

   //compare two selected cards
   useEffect(()=>{
     if(choiceOne && choiceTwo){
      setDisabled(true)
      if(choiceOne.src === choiceTwo.src){
         setCards(prevCards=> {
         return  prevCards.map(card => {
          if(card.src === choiceOne.src){
            return {...card, matched:true}
          }else{
            return card
          }
         })
         })
        resetTurn()
      }else{
       
        setTimeout(()=> resetTurn(), 1000)
      }
     }
   },[choiceOne,choiceTwo])
   console.log(cards)
   const resetTurn = ()=>{
    setChoiceOne(null)
    setChoiceTwo(null)
    setTurns(prevTurns => prevTurns+1)
    setDisabled(false)
   }

   // start game automatically

   useEffect(()=>{
    shuffleCards()
   },[])

   const closeModal = ()=>{
      setIsShowModal(false)
   }

  return (
    <div className="App" >
      <h1>Memory Game</h1>
      <p style={{ fontSize:"12px"}}>The target is to open all boxes, with the condition that unless you open two boxes with same image, the boxes close again. One who achieve this with least number of strikes, is the winner </p>
      <button onClick={shuffleCards}>Refresh</button>
      <div className='outerbox'>
      <div className='card-grid'>
        {cards.map(card=>(
         <SingleCard
          key={card.id}
          card={card} 
          handleChoice = {handleChoice}
          flipped = {card === choiceOne || card === choiceTwo || card.matched}
          disabled = {disabled}
           />
        ))}
      </div>
      </div>
     
      <p>Strikes:  <span className='turns'>{turns}</span></p>
     <div class="author">
        <p class="author_text">developed by : <span class="name">Solomon Sudhir</span></p>
     </div>
    </div>
    
  );
}

export default App